<template>
  <div class="w-100 my-5">
    <div v-if="eventList">
      <div class="row mx-auto box-white">
        <div class="col-sm my-1 vertical-center" v-if="organizerInfo">
          <img class="circle-image-30" :src="organizerInfo.thumbnailUrl || organizerInfo.photoUrl || assets.profile" />
          <span class="ml-2 font-15 font-bold">{{ organizerInfo.userName }}</span>
        </div>
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07">{{ $t('drink') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
        </div>
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
      <div class="row my-5" v-if="(items.length > 0)">
        <div :class="`col-sm${colSmSize}`" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_100, items.length))" :key="(item, index)">
          <v-card class="my-2 p-0 box-white d-flex flex-column">
            <v-img style="cursor: zoom-in" height="200px" :src="item.photoUrl" @click="openImageViewer(index)" />
            <div class="m-2 vertical-center" v-if="!organizerInfo">
              <img class="circle-image-30" :src="item.userPhotoUrl" />
              <span class="ml-2 ellipsis font-12" :title="item.userName">{{ item.userName }}</span>
            </div>
            <div class="mt-1 mx-3 mt-1 vertical-center">
              <i class="fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
            </div>
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.eventId }}</span>
            <span class="mx-3 mt-1 ellipsis font-bold font-15" :title="item.name">{{ item.name }}</span>
            <span class="mx-3 mt-2 ellipsis font-12" :title="item.description">{{ item.description }}</span>
            <div class="mx-3 mt-2 vertical-center">
              <i class="flaticon-placeholder-2 font-12 color-purple"></i>
              <span class="ml-2 ellipsis font-12 color-purple" :title="item.address">{{ item.address }}</span>
              <span class="ml-2 font-12 color-red" v-if="item.hideAddress">{{ $t('private') }}</span>
            </div>
            <div class="mx-3 mt-2 vertical-center justify-content-between">
              <div class="vertical-center">
                <i class="fa fa-calendar-alt font-12 color-blue"></i>
                <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
              </div>
              <div class="vertical-center" v-if="item.deletedAt">
                <i class="fa fa-calendar-alt font-12 color-red"></i>
                <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.deletedAt) }}</span>
                &nbsp;&nbsp;
                <i class="font-12 color-red">[{{ $t('deleted') }}]</i>
              </div>
            </div>
            <div class="m-2 div-divider-h" />
            <div class="m-2 d-flex justify-content-between vertical-center">
              <span class="font-12 font-bold">{{ getDecimalFormat(item.drinkSoldQuantity || 0) }} / {{ getDecimalFormat(item.drinkTotalQuantity || 0) }}</span>
              <span class="font-15 font-bold color-blue">{{ getPriceFormat(item.drinkPayoutAmount || 0) }}</span>
            </div>
            <div class="m-2 d-flex justify-content-end vertical-center">
              <span class="mr-auto font-12 font-bold color-red" v-if="item.onlyVisibleToMe === true">Only visible to Organizer</span>
              <v-btn class="ml-5 button-normal" small @click="$router.push(`/drink/orders/${item.eventId}`)" :disabled="!item.drinkSoldQuantity">
                <span>{{ $t('orders') }}</span>
              </v-btn>
              <v-btn class="ml-5 button-red" small @click="$router.push(`/drink/payout/${item.eventId}`)" :disabled="!item.drinkSoldQuantity">
                <span>{{ $t('withdraw') }}</span>
              </v-btn>
          </div>
          </v-card>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_100 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <!-- VueEasyLightbox Component -->
    <VueEasyLightbox
      :visible="visibleImageViewer"
      :imgs="images"
      :index="imageIndex"
      @hide="visibleImageViewer = false"
      />
    <!-- VueEasyLightbox Component end -->
  </div>
</template>


<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import { getDateStringFromTimestamp, getDecimalFormat, getPriceFormat } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'DrinkList',
  components: {
    VueEasyLightbox
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    organizerInfo() {
      if (this.$route.params.organizerId) {
        return this.organizerList.find(element => element.userId === this.$route.params.organizerId);
      } else {
        return null;
      }
    },
    eventList() {
      const eventList = this.$store.state.eventList;
      if (eventList) {
        return eventList.filter(element => element.drinkTotalQuantity);
      } else {
        return null;
      }
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshData();
      }
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      hostname: window.location.hostname,
      visibleImageViewer: false,
      imageIndex: 0,
      images: [],
      pageIndex: 1,
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    getDecimalFormat,
    getPriceFormat,
    refreshData() {
      if (this.eventList && this.organizerList) {
        const items = [];
        const keyword = this.keyword.toLowerCase();
        if (this.$route.params.organizerId) {
          this.eventList.filter(element => element.userId === this.$route.params.organizerId).forEach(eventInfo => {
            if (eventInfo.name.toLowerCase().includes(keyword)) {
              items.push(eventInfo);
            }
          });
        } else {
          this.eventList.forEach(eventInfo => {
            const userInfo = this.organizerList.find(element => element.userId === eventInfo.userId);
            if (userInfo && (eventInfo.name.toLowerCase().includes(keyword) || userInfo.userName.toLowerCase().includes(keyword))) {
              const item = JSON.parse(JSON.stringify(eventInfo));
              item['userName'] = userInfo.userName;
              item['userPhotoUrl'] = userInfo.thumbnailUrl || userInfo.photoUrl || this.assets.profile;
              items.push(item);
            }
          });
        }
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.images = [];
        items.forEach(info => {
          this.images.push({
            title: info.name,
            src: info.photoUrl
          });
        });
        this.pageIndex = 1;
        this.items = items;
      }
    },
    openImageViewer(index) {
      this.imageIndex = index;
      this.visibleImageViewer = true;
    }
  }
};
</script>