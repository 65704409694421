import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import VueMobileDetection from "vue-mobile-detection";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

Vue.config.productionTip = false;
Vue.use(VueMobileDetection);
Vue.use(VueLoading);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 5000
});

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

Vue.mixin({
  data: function() {
    return {
      ADMIN_UID: 'TaOcOF7WvBX9fE987DMGxYaX4262',
      RESULT_SUCCESS: 1000,
      RESULT_TICKET_ALREADY_REFUNDED: -1016,
      ADMIN_FUNCTION: 'adminFunction',
      PAYMENT_TYPE_FREE: 'Free',
      PAYMENT_TYPE_VIP: 'VIP',
      PAYMENT_TYPE_PAYPAL: 'PayPal',
      PAYMENT_TYPE_PAYPAL_SANDBOX: 'PayPal-Sandbox',
      PAYMENT_TYPE_STRIPE: 'Stripe',
      PAYMENT_TYPE_STRIPE_TEST: 'Stripe-Test',
      PAYMENT_TYPE_CARD: 'Card',
      PAYMENT_TYPE_CASH: 'Cash',
      PAGE_LIMIT_100: 100,
      TEST_PREFIX: 'Test-'
    }
  }
});

router.beforeEach((to, from, next) => {
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDSVS0zmdro2sBDa6SuwyqGvBkWvHVZYOo",
  authDomain: "eventbox-c7c8f.firebaseapp.com",
  projectId: "eventbox-c7c8f",
  storageBucket: "eventbox-c7c8f.appspot.com",
  messagingSenderId: "1011853757251",
  appId: "1:1011853757251:web:dce0221c2c209db7063df6",
  measurementId: "G-BCWXJB1YPP"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west1');