<template>
  <div class="w-100 my-5">
    <div class="m-2 row box-white vertical-center d-flex justify-content-between">
      <div class="m-2 col-sm vertical-center">
        <span class="font-bold font-15 alpha-07">{{ $t('transaction') }}:</span>
        <span class="mx-5 font-bold font-20 color-blue">{{ getDecimalFormat(transactions) }}</span>
      </div>
      <div class="m-2 col-sm vertical-center">
        <span class="font-bold font-15 alpha-07">Total Earnings:</span>
        <span class="mx-5 font-bold font-20 color-blue">{{ getPriceFormat(totalEarning) }}</span>
      </div>
      <div class="m-2 col-sm vertical-center d-flex justify-content-between">
        <div class="vertical-center">
          <span class="mr-5 font-12 font-bold">{{ $t('select_year') }}</span>
          <select v-model="selectedYear">
            <option :value="year" v-for="year in years" :key="year" :selected="year == selectedYear">{{ year }}</option>
          </select>
        </div>
        <v-btn class="ml-auto button-normal" @click="generateReport()">
          <span>{{ $t('report') }}</span>
        </v-btn>
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('total_price') }}</span>
        <span :class="`mt-3 font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(totalPrice) }}</span>
        <div>
          <span class="font-15 color-red">{{ getPriceFormat(totalPriceRefunded) }}</span>
          <span class="ml-2 font-12 alpha-07">{{ $t('refunded') }}</span>
        </div>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('service_charge') }}</span>
        <span :class="`mt-3 font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(serviceCharge) }}</span>
        <div>
          <span class="font-15 color-red">{{ getPriceFormat(serviceChargeRefunded) }}</span>
          <span class="ml-2 font-12 alpha-07">{{ $t('refunded') }}</span>
        </div>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('handling_fee') }}</span>
        <span :class="`mt-3 font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(handlingFee) }}</span>
        <div>
          <span class="font-15 color-red">{{ getPriceFormat(refundFee) }}</span>
          <span class="ml-2 font-12 alpha-07">{{ $t('refund_fee') }}</span>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1900"
      :pdf-quality="2"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-end">
            <img :src="assets.logo" :height="30" />
          </div>
          <span class="my-10 font-20 font-bold color-black">Ticket Report - {{ selectedYear }}</span>
          <div class="mt-5 d-flex justify-content-between">
            <span class="font-15 color-black">Total Earnings</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(totalEarning) }}</span>
          </div>
          <div class="mt-5 ml-2 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('total_price') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(totalPrice) }}</span>
          </div>
          <div class="mt-2 ml-5 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('refunded') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(totalPriceRefunded) }}</span>
          </div>
          <div class="mt-5 ml-2 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('service_charge') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(serviceCharge) }}</span>
          </div>
          <div class="mt-2 ml-5 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('refunded') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(serviceChargeRefunded) }}</span>
          </div>
          <div class="mt-5 ml-2 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('handling_fee') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(handlingFee) }}</span>
          </div>
          <div class="mt-2 ml-5 d-flex justify-content-between">
            <span class="font-15 color-black">{{ $t('refund_fee') }}</span>
            <span class="font-15 font-bold color-black">{{ getPriceFormat(refundFee) }}</span>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';

import { firestore } from '../../../main';
import { collection, query, orderBy, startAfter, endBefore, getDocs, Timestamp } from 'firebase/firestore';
import { getPriceFormat, getDecimalFormat, showLoading } from '../../../functions';
import logo from '@/assets/image/logo_for_invoice.png';

export default {
  name: 'TicketReport',
  components: {
    VueHtml2pdf
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  watch: {
    selectedYear() {
      this.getTransactionListTask();
    }
  },
  data() {
    return {
      assets: {
        logo
      },
      htmlToPdfOptions: {
        margin: 10,
        filename: 'TicketReport',
        image: {
          type: 'jpeg',
          quality: 1.0
        },
        html2canvas: {
          scale: 1,
          useCORS: true
        }
      },
      transactions: 0,
      totalEarning: 0,
      totalPrice: 0,
      totalPriceRefunded: 0,
      handlingFee: 0,
      refundFee: 0,
      serviceCharge: 0,
      serviceChargeRefunded: 0,
      selectedYear: new Date().getFullYear(),
      years: []
    }
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (var year=2023; year<=currentYear; year++) {
      this.years.push(year);
    }
    this.getTransactionListTask();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    generateReport() {
      this.htmlToPdfOptions.filename = `TicketReport_${this.selectedYear}`;
      this.$refs.html2Pdf.generatePdf()
    },
    getTransactionListTask() {
      const startDate = new Date();
      startDate.setFullYear(this.selectedYear);
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const endDate = new Date();
      endDate.setFullYear(this.selectedYear + 1);
      endDate.setMonth(0);
      endDate.setDate(1);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      const startTimestamp = Timestamp.fromDate(startDate);
      const endTimestamp = Timestamp.fromDate(endDate);
      const loader = showLoading(this);
      const q = query(collection(firestore, 'transaction'), orderBy('createdAt', 'asc'), startAfter(startTimestamp), endBefore(endTimestamp));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        var transactions = 0;
        var totalEarning = 0;
        var totalPrice = 0;
        var totalPriceRefunded = 0;
        var handlingFee = 0;
        var refundFee = 0;
        var serviceCharge = 0;
        var serviceChargeRefunded = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.totalPrice > 0 && !data.eventId.startsWith(this.TEST_PREFIX)) {
            transactions++;
            totalPrice += data.totalPrice || 0;
            handlingFee += data.handlingFee || 0;
            serviceCharge += data.serviceCharge || 0;
            if (data.refundRequestedAt) {
              totalPriceRefunded += data.totalPrice || 0;
              serviceChargeRefunded += data.serviceCharge || 0;
              refundFee += data.refundFee || 0;
              totalEarning += data.refundFee || 0;
            } else {
              totalEarning += (data.handlingFee || 0) + (data.serviceCharge || 0);
            }
          }
        });
        this.transactions = transactions;
        this.totalEarning = totalEarning;
        this.totalPrice = totalPrice;
        this.totalPriceRefunded = totalPriceRefunded;
        this.handlingFee = handlingFee;
        this.refundFee = refundFee;
        this.serviceCharge = serviceCharge;
        this.serviceChargeRefunded = serviceChargeRefunded;
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    }
  }
};
</script>