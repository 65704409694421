<template>
  <div class="w-100 my-5">
    <div v-if="organizerList">
      <div class="row mx-auto box-white">
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07" v-if="(targetType === 1)">{{ $t('reported_user') }}:</span>
          <span class="font-bold font-15 alpha-07" v-else-if="(targetType === 2)">{{ $t('reported_post') }}:</span>
          <span class="font-bold font-15 alpha-07" v-else-if="(targetType === 3)">{{ $t('reported_event') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
        </div>
      </div>
      <div class="row my-5" v-if="(items.length > 0)">
        <div :class="`col-sm${colSmSize}`" v-for="(item, index) in items" :key="(item, index)">
          <div class="my-2 box-white d-flex flex-column">
            <div class="vertical-center">
              <img class="circle-image-30" :src="item.reporterPhotoUrl" />
              <span class="ml-2 ellipsis font-12" :title="item.reporterName">{{ item.reporterName }}</span>
              <v-btn class="ml-auto" icon small @click="deleteReportInfoTask(item.reportId)">
                <i class="flaticon-delete color-red"></i>
              </v-btn>
            </div>
            <div class="my-2 div-divider-h" />
            <img :class="`mt-2 ${targetType === 1 ? 'circle-image-80' : ''}`" :style="`${targetType !== 1 ? 'height: 150px;' : ''}`" :src="item.targetPhotoUrl" />
            <span class="mt-2 ellipsis font-bold font-12" :title="item.targetName">{{ item.targetName }}</span>
            <div class="my-2 vertical-center">
              <span class="ellipsis font-15 font-bold color-red" :title="item.reason">{{ item.reason }}</span>
              <i class="ml-auto fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="py-40 center" v-else>
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'ReportList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    targetType() {
      return parseInt(this.$route.params.type);
    },
    organizerList() {
      return this.$store.state.organizerList;
    },
    eventList() {
      return this.$store.state.eventList;
    },
    postList() {
      return this.$store.state.postList;
    },
    reportList() {
      return this.$store.state.reportList;
    }
  },
  watch: {
    targetType() {
      this.refreshData();
    },
    organizerList() {
      this.refreshData();
    },
    eventList() {
      this.refreshData();
    },
    postList() {
      this.refreshData();
    },
    reportList() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      items: []
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    refreshData() {
      if (this.reportList && this.organizerList) {
        const items = [];
        this.reportList.filter(element => element.targetType === this.targetType).forEach(reportInfo => {
          const reporterInfo = this.getUserInfo(reportInfo.userId);
          if (reporterInfo) {
            const item = JSON.parse(JSON.stringify(reportInfo));
            item['reporterName'] = reporterInfo.userName;
            item['reporterPhotoUrl'] = reporterInfo.thumbnailUrl || reporterInfo.photoUrl || this.assets.profile;
            if (this.targetType === 1) {
              const userInfo = this.getUserInfo(reportInfo.targetId);
              if (userInfo) {
                item['targetName'] = userInfo.userName;
                item['targetPhotoUrl'] = userInfo.thumbnailUrl || userInfo.photoUrl || this.assets.profile;
              }
            } else if (this.targetType === 2) {
              const postInfo = this.getPostInfo(reportInfo.targetId);
              if (postInfo) {
                item['targetName'] = postInfo.description;
                item['targetPhotoUrl'] = postInfo.photoUrl;
              }
            } else if (this.targetType === 3) {
              const eventInfo = this.getEventInfo(reportInfo.targetId);
              if (eventInfo) {
                item['targetName'] = eventInfo.name;
                item['targetPhotoUrl'] = eventInfo.photoUrl;
              }
            }
            item['reason'] = this.getReportReason(item.reportType);
            items.push(item);
          }
        });
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.items = items;
      }
    },
    getReportReason(reportType) {
      return this.$t(`report_type_${reportType}`);
    },
    getUserInfo(userId) {
      return this.organizerList ? this.organizerList.find(element => element.userId === userId) : null;
    },
    getEventInfo(eventId) {
      return this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
    },
    getPostInfo(postId) {
      return this.postList ? this.postList.find(element => element.postId === postId) : null;
    },
    deleteReportInfoTask(reportId) {
      if (!reportId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm(this.$t('confirm_delete_report'))) {
        return;
      }
      const params = {
        functionName: 'deleteReportInfo',
        reportId: reportId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>