import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    colSmSize: 3,
    statsMap: null,
    userDailyList: [],
    transactionDailyList: [],
    drinkDailyList: [],
    notificationList: [],
    organizerList: null,
    userList: [],
    eventList: null,
    postList: null,
    transactionList: null,
    withdrawList: null,
    reportList: null,
    paypalWebHookList: [],
    transactionTempList: [],
    drinkOrderList: null,
    drinkPayoutList: null,
    storyList: null,
    albumList: null
  },
  mutations: {
    set(state, [variable, value]) {
      state[variable] = value
    }
  },
  modules: {
    htmlClass,
    config
  }
});
