<template>
  <div class="w-100 my-5">
    <div v-if="statsMap && statsMap['user'] && statsMap['transaction'] && statsMap['drink'] && statsMap['withdraw']">
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="flaticon-users-1 font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('user') }}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getDecimalFormat(statsMap['user'].total) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('unapproved') }}</span>
            <span class="font-15 color-red">{{ getDecimalFormat(statsMap['user'].unapproved) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('deleted') }}</span>
            <span class="font-15 color-red">{{ getDecimalFormat(statsMap['user'].deletedUser) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">Web</span>
            <span class="font-15" style="color: #1FC266;">{{ getDecimalFormat(statsMap['user'].userWeb || 0) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">Android</span>
            <span class="font-15" style="color: #C0504E;">{{ getDecimalFormat(statsMap['user'].userAndroid || 0) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">iOS</span>
            <span class="font-15" style="color: #4F81BC;">{{ getDecimalFormat(statsMap['user'].userIOS || 0) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="flaticon-users-1 font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('organizer' )}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getDecimalFormat(statsMap['user'].organizer) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('deleted') }}</span>
            <span class="font-15 color-red">{{ getDecimalFormat(statsMap['user'].deletedOrganizer) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="flaticon-event-calendar-symbol font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('event' )}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getDecimalFormat(statsMap['event'].total) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('deleted') }}</span>
            <span class="font-15 color-red">{{ getDecimalFormat(statsMap['event'].deleted) }}</span>
          </div>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;Ticket&nbsp;{{ $t('net_sale')}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(statsMap['transaction'].serviceCharge + statsMap['transaction'].handlingFee - statsMap['transaction'].refundPaidServiceCharge + statsMap['transaction'].refundFee) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('service_charge') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['transaction'].serviceCharge) }}</span>
          </div>
          <div class="ml-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('refunded') }}</span>
            <span class="font-15 color-red">{{ getPriceFormat(statsMap['transaction'].refundRequestedServiceCharge) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('handling_fee') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['transaction'].handlingFee) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('refund_fee') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['transaction'].refundFee) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;Ticket&nbsp;{{ $t('gross_sale')}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(statsMap['transaction'].totalPrice) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('ticket_price') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['transaction'].ticketPrice) }}</span>
          </div>
          <div class="ml-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('promo_discount') }}</span>
            <span class="font-15 color-red">{{ getPriceFormat(statsMap['transaction'].promoDiscount) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('refunded') }}</span>
            <span class="font-15 color-red">{{ getPriceFormat(statsMap['transaction'].refundRequestedAmount) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('withdraw') }}</span>
            <span class="font-15 color-red">{{ getPriceFormat(statsMap['withdraw'].paidAmount) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('drink') }}&nbsp;{{ $t('net_sale')}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(statsMap['drink'].handlingFee) }}</span>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('handling_fee') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['drink'].handlingFee) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('drink') }}&nbsp;{{ $t('gross_sale')}}</span>
          <span :class="`font-bold ${isMobile ? 'font-30' : 'font-50'} color-blue`">{{ getPriceFormat(statsMap['drink'].totalPrice) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('drink') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['drink'].drinkPrice) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('tip') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(statsMap['drink'].tipPrice) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('withdraw') }}</span>
            <span class="font-15 color-red">{{ getPriceFormat(statsMap['drink'].payoutPaidAmount) }}</span>
          </div>
        </div>
      </div>
      <p>&nbsp;</p>
      <div class="box-white m-2">
        <span class="font-15 alpha-07">{{ $t('tickets') }}</span>
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-options="chartOptions1"
          :chart-data="chartData1"
        />
      </div>
      <p>&nbsp;</p>
      <div class="box-white m-2">
        <span class="font-15 alpha-07">{{ $t('drink') }}</span>
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-options="chartOptions4"
          :chart-data="chartData4"
        />
      </div>
      <p>&nbsp;</p>
      <div class="box-white m-2">
        <span class="font-15 alpha-07">{{ $t('user') }}</span>
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-options="chartOptions2"
          :chart-data="chartData2"
        />
      </div>
      <p>&nbsp;</p>
      <div class="box-white m-2">
        <span class="font-15 alpha-07">{{ $t('online') }}</span>
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-options="chartOptions3"
          :chart-data="chartData3"
        />
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

import { getDecimalFormat, getPriceFormat } from '../../functions';

export default {
  name: 'Dashboard',
  components: {
    LineChartGenerator
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    chartStyle() {
      return {
        height: `${this.isMobile ? 200 : 400}px`,
        position: 'relative'
      };
    },
    statsMap() {
      return this.$store.state.statsMap;
    },
    transactionDailyList() {
      return this.$store.state.transactionDailyList;
    },
    drinkDailyList() {
      return this.$store.state.drinkDailyList;
    },
    userDailyList() {
      return this.$store.state.userDailyList;
    }
  },
  watch: {
    transactionDailyList() {
      this.generateTransactionChartData();
    },
    drinkDailyList() {
      this.generateDrinkChartData();
    },
    userDailyList() {
      this.generateUserChartData();
    }
  },
  data() {
    return {
      chartData1: {
        labels: [],
        datasets: [
          {
            label: this.$t('service_charge'),
            backgroundColor: '#C0504E',
            borderColor: '#C0504E',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: this.$t('total_price'),
            backgroundColor: '#4F81BC',
            borderColor: '#4F81BC',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions1: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                return getPriceFormat(value, true);
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += getPriceFormat(context.parsed.y);
                }
                return label;
              }
            }
          }
        }
      },
      chartData2: {
        labels: [],
        datasets: [
          {
            label: this.$t('single_user'),
            backgroundColor: '#C0504E',
            borderColor: '#C0504E',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: this.$t('organizer'),
            backgroundColor: '#4F81BC',
            borderColor: '#4F81BC',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: this.$t('online'),
            backgroundColor: '#1FC266',
            borderColor: '#1FC266',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions2: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                return getDecimalFormat(value);
              }
            }
          }
        }
      },
      chartData3: {
        labels: [],
        datasets: [
          {
            label: 'Android',
            backgroundColor: '#C0504E',
            borderColor: '#C0504E',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: 'iOS',
            backgroundColor: '#4F81BC',
            borderColor: '#4F81BC',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: 'Web',
            backgroundColor: '#1FC266',
            borderColor: '#1FC266',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions3: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                return getDecimalFormat(value);
              }
            }
          }
        }
      },
      chartData4: {
        labels: [],
        datasets: [
          {
            label: this.$t('handling_fee'),
            backgroundColor: '#C0504E',
            borderColor: '#C0504E',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          },
          {
            label: this.$t('total_price'),
            backgroundColor: '#4F81BC',
            borderColor: '#4F81BC',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions4: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                return getPriceFormat(value, true);
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += getPriceFormat(context.parsed.y);
                }
                return label;
              }
            }
          }
        }
      },
    }
  },
  mounted() {
    this.generateTransactionChartData();
    this.generateDrinkChartData();
    this.generateUserChartData();
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    generateTransactionChartData() {
      const labels = [];
      const data1 = [];
      const data2 = [];
      this.transactionDailyList.forEach(info => {
        labels.push(info.date);
        data1.push(info.serviceCharge);
        data2.push(info.totalPrice);
      });
      this.chartData1.labels = labels;
      this.chartData1.datasets[0].data = data1;
      this.chartData1.datasets[1].data = data2;
    },
    generateDrinkChartData() {
      const labels = [];
      const data1 = [];
      const data2 = [];
      this.drinkDailyList.forEach(info => {
        labels.push(info.date);
        data1.push(info.handlingFee);
        data2.push(info.totalPrice);
      });
      this.chartData4.labels = labels;
      this.chartData4.datasets[0].data = data1;
      this.chartData4.datasets[1].data = data2;
    },
    generateUserChartData() {
      const labels = [];
      const data21 = [];
      const data22 = [];
      const data23 = [];
      const data31 = [];
      const data32 = [];
      const data33 = [];
      this.userDailyList.forEach(info => {
        labels.push(info.date);
        data21.push(info.registeredTotal || 0);
        data22.push(info.registeredOrganizer || 0);
        data23.push(info.newOnline || 0);
        data31.push(info.userAndroid || 0);
        data32.push(info.userIOS || 0);
        data33.push(info.userWeb || 0);
      });
      this.chartData2.labels = labels;
      this.chartData2.datasets[0].data = data21;
      this.chartData2.datasets[1].data = data22;
      this.chartData2.datasets[2].data = data23;
      this.chartData3.labels = labels;
      this.chartData3.datasets[0].data = data31;
      this.chartData3.datasets[1].data = data32;
      this.chartData3.datasets[2].data = data33;
    }
  }
};
</script>