<template>
  <div class="w-100 my-5">
    <div class="row my-5" v-if="userList">
      <span class="font-bold font-12">UserList: {{ userList.length }}</span>
      <div class="col-sm-12" v-for="(item, index) in userList" :key="(item, index)">
        <v-card class="my-2 box-white d-flex flex-column">
          <div class="m-2 row">
            <span class="col-sm-3">{{ item.userId }}</span>
            <span class="col-sm-4">{{ item.userName }}</span>
            <span class="col-sm-2">{{ item.email }}</span>
            <span class="col-sm">{{ JSON.stringify(item.keywordList) }}</span>
            <v-btn class="ml-auto mr-5 button-normal" small @click="setKeywordListTask(item.userId, item.userName)">
              <span>Set KeywordList</span>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { showLoading } from '../../../functions';

export default {
  name: 'SetKeywordList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      userList: [],
      snapUserList: null
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    } else {
      this.getUserListTask();
    }
  },
  destroyed() {
    if (this.snapUserList) {
      this.snapUserList();
    }
  },
  methods: {
    getUserListTask() {
      if (this.snapUserList) {
        return;
      }
      const loader = showLoading(this, 1);
      const q = query(collection(firestore, 'user'), where('userType', '==', 0), where('keywordList', '==', []));
      this.snapUserList = onSnapshot(q, querySnapshot => {
        loader.hide();
        const list = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          list.push(data);
        });
        this.userList = list;
      });
    },
    setKeywordListTask(userId, userName) {
      if (!userId) {
        this.$toast.info('Invalid user ID.');
        return;
      }
      if (!userName) {
        this.$toast.info('Invalid user name.');
        return;
      }
      const params = {
        functionName: 'setKeywordList',
        doSet: false,
        userId: userId,
        userName: userName
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>