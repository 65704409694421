<template>
  <div class="w-100 my-5">
    <div class="row mx-auto">
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('total_balance') }}</span>
        <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(totalBalance) }}</span>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('not_paid') }}</span>
        <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(notPaid) }}</span>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('already_paid') }}</span>
        <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(alreadyPaid) }}</span>
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-sm m-2 box-white row">
        <div class="col-sm my-1 vertical-center" v-if="eventInfo">
          <img :width="60" :height="40" :src="eventInfo.photoUrl" />
          <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
        </div>
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07">{{ $t('refund') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue" v-if="$route.params.eventId">{{ getDecimalFormat(items.length) }}</span>
          <span class="mx-5 font-bold font-20 color-blue" v-else>{{ `${getDecimalFormat(items.length)}${statsMap && statsMap['transaction'] ? ` / ${getDecimalFormat(statsMap['transaction'].refunds)}` : ''}` }}</span>
        </div>
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="row mx-auto" v-for="(item, index) in items" :key="(item, index)">
      <div class="col-sm m-2 p-0 box-white row">
        <div class="col-sm d-flex flex-column">
          <div class="my-1 vertical-center">
            <img class="circle-image-30" :src="item.organizerPhotoUrl" />
            <span class="ml-2 ellipsis font-12" :title="item.organizerName">{{ item.organizerName }}</span>
          </div>
          <img width="200px" :src="item.eventPhotoUrl" />
          <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm-4 d-flex flex-column">
          <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.transactionId }}</span>
          <span class="my-2 font-bold font-15">{{ $t('tickets') }}</span>
          <div class="mt-2 d-flex justify-content-between" v-for="(detail, index2) in item.details" :key="(detail, index2)">
            <span class="w-50 font-12" v-if="detail.eventDays">{{ `${detail.ticketName} (${$t('event_days')}: ${detail.eventDays})` }}</span>
            <span class="w-50 font-12" v-else>{{ detail.ticketName }}</span>
            <span class="ml-auto font-12">{{ `${getPriceFormat(detail.price)} * ${detail.quantity} = ${getPriceFormat(detail.price * detail.quantity)}` }}</span>
          </div>
          <span class="mt-5 font-12 color-red" v-if="item.promoCode && item.promoPercent">{{ $t('discounted_', [item.promoPercent, item.promoCode]) }}</span>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm d-flex flex-column">
          <div class="my-1 vertical-center">
            <img class="circle-image-30" :src="item.buyerPhotoUrl" />
            <span class="ml-2 ellipsis font-12" :title="item.buyerName">{{ item.buyerName }}</span>
          </div>
          <div class="mt-5 d-flex justify-content-between">
            <span class="font-12">{{ $t('ticket_price') }}</span>
            <span class="ml-2 font-12">{{ getPriceFormat(item.ticketPrice) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between" v-if="item.promoDiscount">
            <span class="font-12">{{ $t('promo_discount') }}</span>
            <span class="ml-2 font-12 color-red">{{ getPriceFormat(item.promoDiscount * -1) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12">{{ $t('service_charge') }}</span>
            <span class="ml-2 font-12">{{ getPriceFormat(item.serviceCharge) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-15">{{ $t('total_price') }}</span>
            <span class="ml-2 font-bold font-15">{{ getPriceFormat(item.totalPrice) }}</span>
          </div>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm d-flex flex-column">
          <span class="mt-2 font-bold font-15 color-red" v-if="item.transactionId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.transactionId.startsWith(PAYMENT_TYPE_STRIPE_TEST)">Sandbox</span>
          <div class="mt-2 d-flex vertical-center">
            <img height="30px" :src="item.transactionId.startsWith(PAYMENT_TYPE_PAYPAL) ? assets.paypal : assets.stripe" />
            <img class="ml-2" height="30px" :src="assets[item.paymentMethod]" v-if="item.paymentMethod && item.paymentMethod !== 'paypal'" />
            <span class="ml-auto font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <span class="font-12">{{ $t('requested') }}</span>
            <span class="ml-2 font-bold font-12">{{ getDateStringFromTimestamp(item.refundRequestedAt) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between" v-if="item.refundRequestedBy">
            <span class="font-12 color-red">{{ $t('requested_by') }}</span>
            <span class="ml-2 font-bold font-12 color-red">{{ item.refundRequestedBy }}</span>
          </div>
          <div class="mt-5 d-flex justify-content-between" v-if="item.refundPaidAt">
            <span class="font-12 color-blue">{{ $t('paid') }}</span>
            <span class="ml-2 font-12 font-bold color-blue">{{ getDateStringFromTimestamp(item.refundPaidAt) }}</span>
          </div>
          <div class="mt-5 d-flex justify-content-between" v-else-if="item.refundPendingAt">
            <span class="font-12 color-red">{{ $t('pending') }}</span>
            <span class="ml-2 font-12 font-bold color-red">{{ getDateStringFromTimestamp(item.refundPendingAt) }}</span>
          </div>
          <div class="mt-5 d-flex flex-column" v-else>
            <v-btn class="ml-auto button-normal" small @click="doRefundTask(item.transactionId, item.totalPrice)">
              <span>{{ $t('refund_now') }}</span>
            </v-btn>
            <v-btn class="my-2 ml-auto button-red" small @click="cancelRefundTask(item.transactionId)">
              <span>{{ $t('cancel_refund') }}</span>
            </v-btn>
          </div>
          <div class="mt-2 d-flex justify-content-between" v-if="item.refundPaidBy">
            <span class="font-12 color-red">{{ $t('paid_by') }}</span>
            <span class="ml-2 font-bold font-12 color-red">{{ item.refundPaidBy }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 d-flex justify-content-center" v-if="hasMore">
      <v-btn text small @click="getTransactionListTask()">
        <span>Load More</span>
      </v-btn>
    </div>
    <div class="py-40 center" v-if="(items.length === 0)">
      <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, orderBy, limit, startAfter, getDocs, Timestamp } from 'firebase/firestore';
import { getPriceFormat, getDecimalFormat, getDateStringFromTimestamp, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import paypal from '@/assets/image/paypal.png';
import stripe from '@/assets/image/stripe.png';
import card from '@/assets/image/card.png';
import sepa from '@/assets/image/sepa.png';
import sepa_debit from '@/assets/image/sepa.png';
import sofort from '@/assets/image/sofort.png';
import klarna from '@/assets/image/klarna.png';
import giropay from '@/assets/image/giropay.png';

export default {
  name: 'RefundList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    statsMap() {
      return this.$store.state.statsMap;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      this.keyword = '';
      this.transactionList = [];
      this.getTransactionListTask();
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        paypal,
        stripe,
        card,
        sepa,
        sepa_debit,
        sofort,
        klarna,
        giropay
      },
      hostname: window.location.hostname,
      totalBalance: 0,
      notPaid: 0,
      alreadyPaid: 0,
      pageIndex: 1,
      hasMore: false,
      transactionList: [],
      cacheEventList: [],
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.cacheUserList = this.$store.state.userList;
    this.transactionList = [];
    this.getTransactionListTask();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    getDateStringFromTimestamp,
    refreshData() {
      var totalBalance = 0;
      var notPaid = 0;
      var alreadyPaid = 0;
      const items = [];
      const keyword = this.keyword.toLowerCase();
      this.transactionList.filter(element => element.refundRequestedAt && (!this.$route.params.eventId || element.eventId === this.$route.params.eventId)).forEach(transactionInfo => {
        const item = JSON.parse(JSON.stringify(transactionInfo));
        const buyerInfo = this.cacheUserList.find(element => element.userId === transactionInfo.buyerId);
        const organizerInfo = this.organizerList.find(element => element.userId === transactionInfo.organizerId);
        const eventInfo = this.getEventInfo(transactionInfo.eventId);
        item['buyerName'] = buyerInfo ? buyerInfo.userName : '';
        item['buyerPhotoUrl'] = buyerInfo ? buyerInfo.thumbnailUrl || buyerInfo.photoUrl || this.assets.profile : this.assets.profile;
        item['organizerName'] = organizerInfo ? organizerInfo.userName : '';
        item['organizerPhotoUrl'] = organizerInfo ? organizerInfo.thumbnailUrl || organizerInfo.photoUrl || this.assets.profile : this.assets.profile;
        item['eventName'] = eventInfo ? eventInfo.name : '';
        item['eventPhotoUrl'] = eventInfo ? eventInfo.thumbnailUrl || eventInfo.photoUrl : '';
        if (!keyword || !eventInfo || !buyerInfo || !organizerInfo ||
            (eventInfo && eventInfo.name.toLowerCase().includes(keyword)) ||
            (buyerInfo && buyerInfo.userName.toLowerCase().includes(keyword)) ||
            (organizerInfo && organizerInfo.userName.toLowerCase().includes(keyword))) {
          totalBalance += transactionInfo.totalPrice;
          if (transactionInfo.refundPaidAt) {
            alreadyPaid += transactionInfo.totalPrice;
          } else {
            notPaid += transactionInfo.totalPrice;
          }
          items.push(item);
        }
      });
      items.sort((a, b) => (a.refundRequestedAt.seconds < b.refundRequestedAt.seconds) ? 1 : -1);
      this.items = items;
      this.totalBalance = totalBalance;
      this.notPaid = notPaid;
      this.alreadyPaid = alreadyPaid;
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    getTransactionListTask() {
      const loader = showLoading(this, 1);
      let q;
      if (this.$route.params.eventId) {
        if (this.transactionList && this.transactionList.length > 0) {
          q = query(collection(firestore, 'transaction'), where('eventId', '==', this.$route.params.eventId), orderBy('refundRequestedAt', 'desc'), limit(this.PAGE_LIMIT_100), startAfter(this.transactionList[this.transactionList.length - 1].refundRequestedAt));
        } else {
          q = query(collection(firestore, 'transaction'), where('eventId', '==', this.$route.params.eventId), orderBy('refundRequestedAt', 'desc'), limit(this.PAGE_LIMIT_100));
        }
      } else {
        if (this.transactionList && this.transactionList.length > 0) {
          q = query(collection(firestore, 'transaction'), orderBy('refundRequestedAt', 'desc'), limit(this.PAGE_LIMIT_100), startAfter(this.transactionList[this.transactionList.length - 1].refundRequestedAt));
        } else {
          q = query(collection(firestore, 'transaction'), orderBy('refundRequestedAt', 'desc'), limit(this.PAGE_LIMIT_100));
        }
      }
      getDocs(q).then(querySnapshot => {
        loader.hide();
        const list = [];
        var userIDs = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (this.hostname === 'localhost' || !data.eventId.startsWith(this.TEST_PREFIX)) {
            list.push(data);
            if (!this.cacheUserList.find(element => element.userId === data.buyerId)) {
              userIDs.push(data.buyerId);
              if (userIDs.length >= 30) {
                this.getUserListTask(userIDs);
                userIDs = [];
              }
            }
          }
        });
        this.hasMore = querySnapshot.docs.length === this.PAGE_LIMIT_100;
        this.transactionList = this.transactionList.concat(list);
        this.refreshData();
        if (userIDs.length > 0) {
          this.getUserListTask(userIDs);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    },
    getUserListTask(userIDs) {
      const q = query(collection(firestore, 'user'), where('userId', 'in', userIDs));
      getDocs(q).then(querySnapshot => {
        const list = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const deviceType = data.deviceType || '';
          if (window.location.hostname === 'localhost') {
            data['deviceType'] = deviceType;
            if (deviceType.startsWith('Android : ')) {
              data['deviceTypeColor'] = 'color-red';
            } else if (deviceType.startsWith('iOS : ')) {
              data['deviceTypeColor'] = 'color-blue';
            } else if (deviceType.startsWith('Web : ')) {
              data['deviceTypeColor'] = 'color-purple';
            }
          } else {
            const myArray = deviceType.split(' : ');
            if (myArray && myArray.length > 0) {
              data['deviceType'] = myArray[0];
            } else {
              data['deviceType'] = '';
            }
            data['deviceTypeColor'] = 'color-primary';
          }
          if (data.userId === this.$route.params.userId) {
            this.userInfo = data;
          }
          list.push(data);
        });
        this.cacheUserList = this.cacheUserList.concat(list);
        this.$store.commit('set', ['userList', this.cacheUserList]);
        this.refreshData();
      });
    },
    doRefundTask(transactionId, amount, refundAnyway = false) {
      if (!transactionId || !amount) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!refundAnyway && !confirm('Are you sure you want to refund this payment?')) {
        return;
      }
      const params = {
        functionName: 'doRefund',
        transactionId: transactionId,
        amount: amount
      };
      if (refundAnyway === true) {
        params['refundAnyway'] = true;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          for (var i = this.items.length; i--;) {
            if (this.items[i].transactionId === transactionId) {
              this.items[i]['refundPaidAt'] = Timestamp.now();
            }
          }
          this.$toast.success('Refunded successfully.');
        } else if (response.data === this.RESULT_TICKET_ALREADY_REFUNDED) {
          if (confirm('This payment is already refunded by Stripe, do you want to set as Refund anyway?')) {
            this.doRefundTask(transactionId, amount, true);
          }
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    cancelRefundTask(transactionId) {
      if (!transactionId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm('Are you sure you want to cancel this refund?')) {
        return;
      }
      const params = {
        functionName: 'cancelRefund',
        transactionId: transactionId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          for (var i = this.items.length; i--;) {
            if (this.items[i].transactionId === transactionId) {
              this.items.splice(i, 1);
            }
          }
          this.$toast.success('Canceled successfully.');
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>